/* App.css */
.slanted-button,
.slt-btn {
  position: relative;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.3s;
  transform: skew(-20deg);
  margin-right: -10px;
  font-size: 14px;
}

.slanted-button:hover {
  transform: skew(-20deg) translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.slanted-button > * {
  transform: skew(20deg);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .slanted-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .icon {
    font-size: 16px;
  }
}

/* App.css */
.slant-button {
  position: relative;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.3s;
  /*   transform: skew(-20deg); */
  margin-right: -10px;
  font-size: 14px;
}

/* Markdown Styles */
.markdown-preview {
  width: 100%;
  padding: 20px;
  color: #333;
}

.dark .markdown-preview {
  color: #fff;
}

.markdown-preview h1,
.markdown-preview h2,
.markdown-preview h3,
.markdown-preview h4,
.markdown-preview h5,
.markdown-preview h6 {
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-weight: 600;
  line-height: 1.25;
}

.markdown-preview h1 {
  font-size: 2em;
}
.markdown-preview h2 {
  font-size: 1.75em;
}
.markdown-preview h3 {
  font-size: 1.5em;
}
.markdown-preview h4 {
  font-size: 1.25em;
}
.markdown-preview h5 {
  font-size: 1.1em;
}
.markdown-preview h6 {
  font-size: 1em;
}

.markdown-preview p,
.markdown-preview ul,
.markdown-preview ol {
  margin-bottom: 1em;
  line-height: 1.6;
}

.markdown-preview ul,
.markdown-preview ol {
  padding-left: 2em;
}

.markdown-preview li {
  margin-bottom: 0.5em;
}

.markdown-preview a {
  color: #3182ce;
  text-decoration: none;
}

.markdown-preview a:hover {
  text-decoration: underline;
}

.dark .markdown-preview a {
  color: #63b3ed;
}

.markdown-preview blockquote {
  margin: 1em 0;
  padding: 0.5em 1em;
  border-left: 4px solid #e2e8f0;
  background-color: #f8f9fa;
}

.dark .markdown-preview blockquote {
  border-left-color: #4a5568;
  background-color: #2d3748;
}

.markdown-preview code {
  padding: 0.2em 0.4em;
  background-color: #f7f7f7;
  border-radius: 3px;
  font-family: monospace;
  font-size: 0.9em;
}

.dark .markdown-preview code {
  background-color: #2d3748;
}

.markdown-preview pre {
  margin: 1em 0;
  padding: 1em;
  background-color: #f7f7f7;
  border-radius: 4px;
  overflow-x: auto;
}

.dark .markdown-preview pre {
  background-color: #2d3748;
}

.markdown-preview table {
  width: 100%;
  margin-bottom: 1em;
  border-collapse: collapse;
}

.markdown-preview th,
.markdown-preview td {
  padding: 0.5em;
  border: 1px solid #e2e8f0;
}

.dark .markdown-preview th,
.dark .markdown-preview td {
  border-color: #4a5568;
}

.markdown-preview img {
  max-width: 100%;
  height: auto;
  margin: 1em 0;
}

.markdown-preview ul {
  list-style-type: disc;
  margin-left: 1.5em;
  margin-bottom: 1em;
}

.markdown-preview ol {
  list-style-type: decimal;
  margin-left: 1.5em;
  margin-bottom: 1em;
}

.markdown-preview ul ul {
  list-style-type: circle;
  margin-top: 0.5em;
}

.markdown-preview ul ul ul {
  list-style-type: square;
}

.markdown-preview li {
  margin-bottom: 0.5em;
  display: list-item;
}

.markdown-preview ul li,
.markdown-preview ol li {
  margin-left: 0.5em;
  padding-left: 0.5em;
}

/* ---------------------------------- */

/* loader */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
